@import '../../styles/variables.scss';

.contact-menu {
	display: flex;

	&__item {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 40px;
		width: 60px;
		height: 60px;
		border-radius: 50%;
		position: relative;
		background-color: $darker;
		flex-shrink: 0;
		box-shadow: 0 0 6px rgba($color: $darker, $alpha: 1);

		@media screen and (min-width: 2000px) {
			width: calc(50px + 1vw);
			height: calc(50px + 1vw);
		}

		@include tabletBig {
			margin-right: 26px;
		}

		@include mobileSmall {
			margin-right: 20px;
		}

		&:last-child {
			margin-right: 0;
		}
	}

	&__icon {
		display: block;
		width: 24px;

		@media screen and (min-width: 2000px) {
			width: calc(24px + 0.3vw);
		}
	}

	svg {
		fill: $snow;
	}

	.title {
		font-family: $indie;
		color: $blue-main;
		backface-visibility: hidden;
		position: absolute;
		top: -30px;
		left: 50%;
		transform: translateX(-35%) rotate(0deg);
		opacity: 0;
		transition: all 0.4s ease;
		white-space: nowrap;
		font-size: 21px;
	}
	a:hover > .title {
		opacity: 1;
		transform: translateX(-55%) rotate(-5deg);
	}
}
