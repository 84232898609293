@import '../../styles/variables';
@import '../../styles/animation';

@mixin titleFont {
	font-family: $Raleway;
	font-weight: 500;
	letter-spacing: 1.2px;
	line-height: 1.3;
}

.wrapper--idea {
	position: relative;
	padding-bottom: 40px;

	@include tabletBig {
		padding: 0 0 40px;
	}

	@include mobile {
		padding-bottom: 0;
	}
}

.addToHome {
	button {
		background: #fff;
		padding: 20px;
		position: fixed;
		right: 20px;
		bottom: 20px;
	}
}

// underline link effect
.nav-link--underline {
	z-index: unset;
	font-size: 1.8rem;
	cursor: default;

	@include tabletBig {
		cursor: pointer;
	}

	&::after {
		z-index: unset;
	}

	&::before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 2px;
		background-color: $blue-main;
		transition: transform 0.4s ease;
		transform: scale(0, 1);
		z-index: 2;
	}

	&:hover::after {
		transform: scale(0, 1);
		height: 2px;
	}

	&:hover::before {
		transform: scale(1, 1);
	}
}

.idea-contacts {
	position: relative;
	display: inline-block;
	margin: min(8vh, 80px) auto 0;
	width: max-content;
	font-family: $LouisGeorgeCaf;
	font-weight: 500;

	@include tabletBig {
		margin-top: 35px;
		line-height: 1.3;
	}

	&__list {
		user-select: text;
		position: absolute;
		bottom: 47px;
		left: 50%;
		transform: translateX(-50%);
		box-shadow: 0 0 10px rgba($color: $black, $alpha: 0.8);
		border-radius: 10px;
		background-color: rgba($color: $black, $alpha: 0.9);
		color: $textGrey;
		display: inline-block;
		height: 0;
		overflow: hidden;
		visibility: hidden;
		transition: 0.4s;
		width: max-content;
		z-index: 1;

		&-items {
			padding: 20px 25px;
		}

		p {
			margin-bottom: 15px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		a {
			color: $blue-main;
			transition: color 0.3s;

			&:hover {
				color: $starYellow;
			}
		}
	}

	&.show {
		.idea-contacts__list {
			visibility: visible;

			&::after {
				transform: translate(-50%, 0) scale(1, 1);
			}
		}

		.nav-link--underline {
			&:after {
				transform: scale(0, 1);
				height: 2px;
			}

			&:before {
				transform: scale(1, 1);
			}
		}
	}
}

.slider-with-btn {
	display: flex;
	width: 100%;

	@include tabletBig {
		margin-left: -40px;
		width: calc(100% + 80px);
		margin-bottom: 25px;
		margin-top: 30px;
	}

	@include mobile {
		margin-left: -15px;
		width: calc(100% + 30px);
	}

	&.disabled {
		.slider-js {
			width: 100%;
		}

		.slider-btn-js,
		.slides-number {
			opacity: 0.4;
			pointer-events: none;
			filter: grayscale(1);

			@include tabletBig {
				opacity: 0;
			}
		}
	}
}

.idea-section {
	background-color: $darken;
	padding: 60px 40px;
	display: flex;
	align-items: flex-start;
	position: relative;
	box-shadow: 0 0 15px rgba($color: $black, $alpha: 1);
	border-radius: 20px;

	@include tabletBig {
		border-radius: 20px;
		max-height: none;
		height: auto;
		flex-direction: column-reverse;
		max-width: 777px;
		width: calc(100% - 80px);
		margin: 0 auto;
		padding-top: 0;
		padding-bottom: 40px;
	}

	@include mobile {
		padding: 0 15px 60px;
		width: 100%;
		border-radius: 0;
		box-shadow: 0 -3px 15px rgba($color: $darkest, $alpha: 1);
	}
}

.idea-info {
	height: 100%;
	width: 52%;
	position: relative;
	overflow: hidden;

	@include tabletBig {
		padding-top: 70px;
		height: auto;
		width: 100%;
		flex-shrink: unset;
		overflow: visible;
	}
}

.copy-tablet {
	display: flex;

	@include tabletBig {
		justify-content: center;
	}
}

.idea-copy {
	display: inline-block;
	font-size: 1.4rem;
	color: $textGrey;
	font-family: $LouisGeorgeCafBold;

	@include tabletBig {
		margin-right: unset;
	}

	p {
		overflow: hidden;
		border-right: 1.5px solid $blue-main;
		white-space: nowrap;
		width: 0;
		animation: typing 2.5s steps(18, end) 2s forwards, blink-caret 1s step-end infinite;
	}

	span {
		font-size: 1.5rem;
	}
}

.startup-action {
	position: sticky;
	top: 0;
	z-index: 10;
	transition: 0.2s;

	&.is-active {
		padding: 0;

		.idea-tabs--urls {
			min-width: 100%;
			border-radius: 0;
			box-shadow: 0 3px 10px rgba($color: $black, $alpha: 1);

			@include tabletBig {
				border-radius: 0;
			}
		}
	}
}

.idea-tabs {
	display: flex;
	width: max-content;
	overflow: hidden;
	font-family: $LouisGeorgeCaf;

	@include tabletBig {
		width: 100%;
	}

	&--urls {
		white-space: nowrap;
		border-radius: 20px;
		box-shadow: 0 1px 10px rgba($color: $black, $alpha: 1);
		letter-spacing: 0;
		margin: 45px auto 40px;
		display: flex;
		justify-content: center;
		background-color: $black;
		min-width: 320px;
		transition: 0.2s;

		@include tabletBig {
			max-width: 777px;
			margin: 25px auto 40px;
			text-align: center;
			border-radius: 10px;
			box-shadow: 0 0 6px rgba($color: $black, $alpha: 1);
			width: calc(100% - 80px);
		}

		@include mobile {
			width: calc(100% - 30px);
		}

		.idea-tabs__btn {
			text-transform: none;
			padding: 10px 40px;
			display: flex;
			align-items: flex-end;
			justify-content: center;
			font-size: 1.6rem;
			letter-spacing: 1px;

			@include tabletBig {
				font-size: 1.4rem;
				padding: 11px 3px 9px;
			}

			&.active {
				font-size: 17px;
				font-family: $LouisGeorgeCafBold;
				text-transform: uppercase;

				@include tabletBig {
					font-size: 14px;
				}
			}

			&:hover {
				text-shadow: 0 0 0.7px $blue-main, 0 0 0.7px $blue-main;
				color: $blue-main;
			}
		}
	}

	// .idea-tabs--lang
	&--lang {
		font-size: 1.3rem;
		box-shadow: 0 0 10px rgba($color: $black, $alpha: 1);
		border-radius: 10px 0 0 10px;
		overflow: hidden;
		font-family: $LouisGeorgeCafBold;
		position: absolute;
		left: 0;
		top: 0;
		transform: translateX(-100%);
		flex-direction: column;

		@include tabletBig {
			transform: translateX(-50%);
			left: 50%;
			border-radius: 0 0 10px 10px;
			margin-bottom: 0;
			width: max-content;
			box-shadow: 0 1px 8px rgba($color: $black, $alpha: 1);
			flex-direction: row;
		}

		@include mobile {
			margin-right: -15px;
		}

		.idea-tabs__btn {
			&:not(.active):hover {
				text-shadow: 0 0 0.7px $blue-main, 0 0 0.7px $blue-main;
				color: $blue-main;
			}
		}
	}

	&__btn {
		background: $black;
		color: $snow;
		padding: 14px 9px;
		text-transform: uppercase;
		line-height: normal;

		@include tabletBig {
			width: 100%;
			flex-grow: 1;
			padding: 9px 20px;
		}

		&.active {
			background: $blue-main;
			text-shadow: 0 0 0.7px $snow;
			color: $snow;
			box-shadow: inset 23px 23px 46px #004d96, inset -23px -23px 46px #006bd0;
		}
	}
}

.startup-title {
	text-align: center;
	font-size: 3.1rem;
	margin-bottom: 40px;
	letter-spacing: 1.4px;
	color: $starYellow;
	@include titleFont;

	@include mobile {
		margin-bottom: 30px;
		font-size: 2.5rem;
	}
}

.startup-ideas {
	.startup-title {
		font-family: $LouisGeorgeCafBold;
		margin-bottom: 0;
		font-size: 1.6rem;
		margin-bottom: 15px;
		// TODO: need color pitch number
		color: $textGrey;

		@include tabletBig {
			text-align: center;
		}

		@include mobile {
			font-size: 1.5rem;
		}
	}
}

.headline {
	&__title {
		margin-bottom: 35px;
		font-size: 3.1rem;
		color: $starYellow;
		text-align: center;
		letter-spacing: 1.4px;
		@include titleFont;

		@include mobile {
			font-size: 2.5rem;
		}
	}
}

.headline-types {
	margin-bottom: 35px;
	font-size: 1.4rem;
	font-weight: 300;
	letter-spacing: 0.8px;

	@include mobile {
		font-size: 1.2rem;
	}

	&__title:not(:last-child) {
		margin-bottom: 8px;
	}

	span {
		font-weight: bold;
	}
}

.idea-content {
	line-height: 1.5;
}

.idea-block {
	font-size: 1.8rem;
	line-height: 1.65;
	color: $textGrey;
	margin-bottom: 45px;

	@include mobile {
		font-size: 1.6rem;
		margin-bottom: 40px;
	}

	&:last-child {
		margin-bottom: 0;
	}

	&__title {
		color: $blue-main;
		font-size: 2.5rem;
		margin-bottom: 20px;
		letter-spacing: 1.2px;
		@include titleFont;

		@include tabletBig {
			font-size: 2.1rem;
		}
	}

	&__subtitle {
		color: $titlePink;
		margin: 25px 0 15px;
		letter-spacing: 1.5px;
	}

	&__text {
		margin-bottom: 30px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	a {
		display: inline;
		position: relative;

		span {
			background-image: linear-gradient(currentColor, currentColor);
			background-size: 100% 1px;
			background-repeat: no-repeat;
			background-position: left bottom;
			transition: background-size 0.3s;
		}

		@include tablet {
			&:focus {
				color: $snow;
			}
		}

		&:hover {
			span {
				background-size: 0 1px;
			}
		}
	}

	ul {
		margin-bottom: 30px;
	}

	li {
		margin-bottom: 20px;
		position: relative;
		display: flex;

		&:last-child {
			margin-bottom: 0;
		}

		&::before {
			content: '';
			display: inline-block;
			flex-shrink: 0;
			width: 4px;
			height: 4px;
			border-radius: 50%;
			background: $titlePink;
			margin-right: 15px;
			margin-top: 13px;

			@include mobile {
				margin-top: 11px;
			}
		}
	}
}

.last-words {
	text-align: right;
	font-style: italic;
	margin-top: 40px;
	font-size: 15px;
	white-space: pre-line;

	@include tabletBig {
		margin-bottom: 40px;
		padding-right: 0;
		font-size: 14px;
	}

	span {
		display: block;
	}
}

// Slider default style
.slider-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	position: relative;

	@include tabletBig {
		align-items: center;
		overflow: hidden;
		height: 350px;
		background-color: $black;
		box-shadow: 0 0 6px rgba($color: $black, $alpha: 1);
	}

	@include mobile {
		height: 250px;
	}
}

.slider-js {
	width: 100%;
	display: flex;
	height: 55vh;
	max-height: 400px;
	transition: transform 0.4s ease-in;
	position: relative;
	overflow: hidden;
	background-color: $black;
	box-shadow: 0 0 10px rgba($color: $black, $alpha: 1);
	border-radius: 0 10px 10px 10px;

	@include tabletBig {
		height: 100%;
		width: calc(100% - 80px);
		box-shadow: unset;
		border-radius: 0;
	}

	@include mobile {
		width: calc(100% - 60px);
	}
}

.slider-item-js {
	width: 100%;
	height: 100%;
	flex-shrink: 0;
	display: flex;
	position: absolute;
	top: 0;
	left: 0;
	color: $textGrey;
	z-index: -1;
	transition: z-index 0.4s ease;
	color: $snow;

	img,
	video {
		object-position: center;
		object-fit: cover;
		height: 100%;
		opacity: 0;
		visibility: hidden;
		transform: scale(1.3);
		transition: transform 0.4s ease-out, opacity 0.4s ease, visibility 0.4s ease;
	}

	video {
		cursor: pointer;
		object-fit: contain;
	}

	&[data-active='true'] {
		z-index: 0;

		img,
		video {
			transform: scale(1);
			opacity: 1;
			visibility: visible;
		}
	}
}

// Video poster preview
.video-preview {
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: $black;
	cursor: pointer;

	&.disabled {
		display: none;
	}

	&:hover {
		color: $blue-main;

		.video-preview__title {
			box-shadow: inset 0 -100px 100px rgba($color: $black, $alpha: 0.9);
		}

		img {
			transform: scale(1.2);
		}

		svg {
			transform: translate(-50%, -50%) scale(1.3);
		}
	}

	svg {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) scale(1);
		width: 120px;
		height: 120px;
		border-radius: 50%;
		background-color: rgba($color: $black, $alpha: 0.7);
		transition: 0.4s ease-out;

		@include tablet {
			width: 90px;
			height: 90px;
		}
	}

	&__title {
		position: absolute;
		bottom: 0;
		left: 0;
		display: flex;
		align-items: flex-end;
		width: 100%;
		height: 100%;
		padding: 20px;
		box-shadow: inset 0 -200px 110px rgba($color: $black, $alpha: 0.8);
		transition: 0.4s ease-out;

		@include tablet {
			padding: 15px;
			font-size: 14px;
			box-shadow: inset 0 -130px 70px rgba($color: $black, $alpha: 0.8);
		}

		@include mobile {
			font-size: 12px;
		}
	}
}

.slides-number {
	color: $blue-main;
	margin: 0 5px;
	min-width: 80px;
	text-align: center;
	@include titleFont;
	font-weight: 600;

	@include tabletBig {
		font-size: 14px;
		position: absolute;
		bottom: 2px;
		right: 55px;
		min-width: unset;
	}

	@include mobile {
		right: 35px;
	}
}

.slider-actions {
	position: relative;
	display: flex;
	align-items: center;
	margin-top: 10px;

	@include tabletBig {
		position: static;
		margin-top: 0;
	}
}

.slider-btn-js {
	padding: 12px;

	@include tabletBig {
		position: absolute;
		top: 0;
		height: 100%;
		background-color: $black;

		&::after {
			content: '';
			display: block;
			width: 20px;
			height: 65%;
			position: absolute;
			top: 0;
		}
	}

	@include mobile {
		padding: 8px;
	}

	.chevron {
		display: block;
		width: 18px;
		height: 18px;
		border: 3px solid $blue-main;
		border-width: 3px 3px 0 0;

		@include mobile {
			width: 16px;
			height: 16px;
		}
	}

	&:hover .chevron {
		border-color: $titlePink;
	}

	&-next {
		@include tabletBig {
			box-shadow: 0 -5px 6px rgba($color: $black, $alpha: 1);

			right: 0;

			&::after {
				right: 100%;
			}
		}

		.chevron {
			transform: rotate(45deg);

			@include tabletBig {
				transform: rotate(45deg) translate(-2px, 2px);
			}
		}
	}

	&-prev {
		margin-left: auto;

		@include tabletBig {
			left: 0;
			box-shadow: 0 -5px 6px rgba($color: $black, $alpha: 1);

			&::after {
				left: 100%;
			}
		}

		.chevron {
			transform: rotate(-135deg);

			@include tabletBig {
				transform: rotate(-135deg) translate(-2px, 2px);
			}
		}
	}
}

@media screen and (min-width: 1281px) {
	// scrollbar styles
	[data-simplebar] {
		position: relative;
		padding-right: 30px;
		z-index: 0;

		@include tabletBig {
			padding-right: 0;
		}
	}

	.simplebar-wrapper {
		overflow: hidden;
	}

	.simplebar-mask {
		direction: inherit;
		position: absolute;
		overflow: hidden;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		width: auto !important;
		height: auto !important;
		z-index: 0;
	}

	.simplebar-offset {
		direction: inherit !important;
		box-sizing: inherit !important;
		resize: none !important;
		position: absolute;
		top: 0 !important;
		left: 0 !important;
		bottom: 0 !important;
		right: 0 !important;
		-webkit-overflow-scrolling: touch;
	}

	.simplebar-content-wrapper {
		direction: inherit;
		box-sizing: border-box !important;
		position: relative;
		display: block;
		height: 100%; /* Required for horizontal native scrollbar to not appear if parent is taller than natural height */
		width: auto;
		max-width: 100%; /* Not required for horizontal scroll to trigger */
		max-height: 100%; /* Needed for vertical scroll to trigger */
		overflow: auto;
		scrollbar-width: none;
		-ms-overflow-style: none;

		&::-webkit-scrollbar {
			display: none;
			width: 0;
		}
	}

	.simplebar-track {
		z-index: 1;
		position: absolute;
		right: 0;
		bottom: 0;
		cursor: pointer;
		overflow: hidden;
	}

	.simplebar-scrollbar {
		position: absolute;
		left: 0;
		right: 0;
		min-height: 10px;
	}

	.simplebar-scrollbar:before {
		content: '';
		background: $blue-main;
		border-radius: 10px;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}

	.simplebar-track.simplebar-vertical {
		top: 0;
		width: 6px;
		border-radius: 10px;
		background: $darkest;
	}
}

.lang-btn {
	&:disabled {
		display: none;
	}
}

.lang-sidebar {
	display: flex;
	width: 50%;
	position: sticky;
	top: 90px;
	margin-left: 85px;

	@include tabletBig {
		margin-left: 0;
		width: 100%;
		position: relative;
		top: 0;
	}
}

.desktop-slider {
	width: 100%;
}

@include tabletBig {
	[data-simplebar],
	.desktop-slider,
	.slider-with-btn .copy-tablet,
	.slider-with-btn .idea-contacts {
		display: none;
	}
}
